<template>
  <div :class="$style.home">
    <BjSearch @refresh="getList" @init="init">
      <BjInput v-model="search.order_no" allow-clear label="搜索订单" v-bind="layout" placeholder="请输入订单编号" />
      <BjSelect
        v-model="search.status"
        v-bind="layout"
        key-field="id"
        label="订单状态"
        placeholder="请选择订单状态"
        value-field="id"
        label-field="name"
        show-all
        :options="typeData"
      />
    </BjSearch>
    <div :class="$style.table">
      <a-row>
        <a-col span="12" :class="$style.title"> 订单列表 </a-col>
        <a-col span="12" class="text-right" />
      </a-row>
      <a-table class="mt-20" :columns="columns" :loading="loading" :data-source="data" :pagination="false" row-key="id">
        <template #avatar="item">
          <div :class="$style.avatar">
            <img :src="item.avatar" />
          </div>
        </template>
        <template #action="item">
          <div v-if="!item.is_master">
            <bj-link type="primary" @click="onDetail(item)"> 详情 </bj-link>
            <bj-link type="primary" @click="onPay(item)"> 立即支付 </bj-link>
            <a-popconfirm title="确定要取消订单吗？" placement="topLeft" @confirm="onDelete(item.id)">
              <bj-link type="danger"> 取消订单 </bj-link>
            </a-popconfirm>
          </div>
          <div v-else>-</div>
        </template>
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="page"
          show-size-changer
          :show-total="total => `共 ${total} 条`"
          :page-size.sync="pageSize"
          :total="total"
          @showSizeChange="onShowSizeChange"
          @change="onPageChange"
        />
      </div>
    </div>

    <bj-modal
      title="订单详情"
      :visible="visible"
      :width="800"
      :body-style="{ 'min-height': '400px' }"
      @ok="visible = false"
      @cancel="visible = false"
    >
      <div :class="$style.modal">
        <div :class="$style.box">
          <div :class="$style.left">订单编号</div>
          <div :class="$style.right">{{ detail.order_no }}</div>
        </div>
        <div :class="$style.box">
          <div :class="$style.left">订单金额</div>
          <div :class="$style.right">{{ detail.order_amount }}</div>
        </div>
        <div :class="$style.box">
          <div :class="$style.left">优惠金额</div>
          <div :class="$style.right">{{ detail.discount_amount }}</div>
        </div>
        <div :class="$style.box">
          <div :class="$style.left">实际支付</div>
          <div :class="$style.right">{{ detail.actual_amount }}</div>
        </div>
        <div :class="$style.box">
          <div :class="$style.left">支付方式</div>
          <div :class="$style.right">{{ detail.payment_name || '-' }}</div>
        </div>
        <div :class="$style.box">
          <div :class="$style.left">订单状态</div>
          <div :class="$style.right">{{ detail.status_name }}</div>
        </div>
        <div :class="$style.line" />
        <a-table :data-source="detail.order_item" :columns="detailColumns" :pagination="false" row-key="id" />
        <div :class="$style.line" />
        <div :class="$style.box">
          <div :class="$style.left">订单操作人</div>
          <div :class="$style.right">{{ log.order }}</div>
        </div>
        <div :class="$style.box">
          <div :class="$style.left">订购时间</div>
          <div :class="$style.right">{{ log.orderTime }}</div>
        </div>
        <div :class="$style.box">
          <div :class="$style.left">支付操作人</div>
          <div :class="$style.right">{{ log.pay || '-' }}</div>
        </div>
        <div :class="$style.box">
          <div :class="$style.left">支付时间</div>
          <div :class="$style.right">{{ log.payTime || '-' }}</div>
        </div>
      </div>
    </bj-modal>
  </div>
</template>

<script>
import { centerOrderService } from '@/service'

const service = new centerOrderService()

export default {
  name: 'Home',
  data() {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      visible: false,
      data: [],
      total: 0,
      loading: false,
      page: 1,
      pageSize: 10,
      search: {
        order_no: null,
        status: 0,
      },
      log: {
        pay: '',
        payTime: '',
        order: '',
        orderTime: '',
      },
      typeData: [
        {
          name: '全部状态',
          id: 0,
        },
        {
          name: '待支付',
          id: 1,
        },
        {
          name: '支付待确认',
          id: 2,
        },
        {
          name: '延迟付款待确认',
          id: 3,
        },
        {
          name: '已完成',
          id: 4,
        },
        {
          name: '已取消',
          id: 5,
        },
      ],
      detail: {},
    }
  },
  computed: {
    columns() {
      return [
        { title: '订单编号', dataIndex: 'order_no' },
        { title: '订单商品', dataIndex: 'properties' },
        { title: '订单金额', dataIndex: 'order_amount', customRender: text => text },
        { title: '实际支付', dataIndex: 'actual_amount', customRender: text => text },
        {
          title: '订单状态',
          dataIndex: 'status',
          customRender: text => <bj-tag type={this.typeName(text)}>{this.statusName(text)}</bj-tag>,
        },
        { title: '订单时间', dataIndex: 'created_at' },
        {
          title: '管理',
          width: 200,
          scopedSlots: {
            customRender: 'action',
          },
        },
      ]
    },
    detailColumns() {
      return [
        { title: '商品类型', dataIndex: 'foreign_type' },
        { title: '商品项目', dataIndex: 'foreign_name' },
        { title: '商品规格', dataIndex: 'properties' },
        { title: '订购类型', dataIndex: 'buy_type' },
        { title: '商品价格', dataIndex: 'price' },
      ]
    },
  },
  created() {
    this.getList()
  },
  methods: {
    typeName(_state) {
      switch (_state) {
        case 1:
          return 'danger'
        case 2:
          return 'warning'
        case 3:
          return 'warning'
        case 4:
          return 'primary'
        case 5:
          return 'default'
      }
    },
    statusName(_state) {
      switch (_state) {
        case 1:
          return '待支付'
        case 2:
          return '支付待确认'
        case 3:
          return '延迟付款待确认'
        case 4:
          return '已完成'
        case 5:
          return '已取消'
      }
    },
    async getList() {
      try {
        const { data } = await service.getList({
          page: this.page,
          page_size: this.pageSize,
          ...this.search,
        })
        this.data = data.record
        this.total = data.total
      } catch (e) {}
    },
    init() {
      this.search = {
        search_type: 1,
        keyword: null,
        status: '',
        role_id: '',
      }
      this.page = 1
      this.getList()
    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onShowSizeChange() {
      this.page = 1
      this.getList()
    },
    async onDelete(id) {
      try {
        await service.cannel({
          id: id,
        })
        this.$message.success('取消成功')
        this.getList()
      } catch (e) {}
    },
    async onDetail(item) {
      try {
        const { data } = await service.getInfo({
          id: item.id,
        })
        this.detail = data
        this.visible = true
        this.detail.order_log.map(item => {
          if (item.operator_type === 1) {
            this.log.order = item.operator
            this.log.orderTime = item.created_at
          }
          if (item.operator_type === 2) {
            this.log.pay = item.operator
            this.log.payTime = item.created_at
          }
        })
      } catch (e) {}
    },
    onPay(item) {
      this.$router.push({
        name: 'commonCashier',
        query: {
          order_id: item.id,
          order_type: item.order_type,
        },
      })
    },
  },
}
</script>

<style lang="less" module>
.home {
  .table {
    min-height: calc(100vh - 240px);
    margin-top: 20px;
    padding: 20px;
    background: #fff;

    .avatar {
      img {
        width: 32px;
        height: 32px;
        object-fit: cover;
        border-radius: 100%;
      }
    }
  }

  .title {
    color: #000;
    font-size: 16px;
  }
}

.modal {
  .box {
    display: flex;
    margin-bottom: 10px;

    .left {
      width: 100px;
    }

    .right {
      flex: 1;
    }
  }

  .line {
    width: 100%;
    border-top: 1px solid #eee;
    margin: 20px 0;
  }
}
</style>
